
import {Component, Vue, Watch} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {
  userViews,
  userIntegrals,
  userTransactions,
  users,
  shops,
  orders,
  coupons,
  couponActivitys,
  userIntegralRequests,
  userRequests,
} from '../../resources';
import {ObjectId} from 'bson';
import lodash from 'lodash';
import {User} from '@/externals/MaxCI-User-v1';
import {UserView} from '@/externals/MaxCI-UserView-v1';
import moment from 'moment';
import {UserIntegral} from '@/externals/MaxCI-UserIntegral-v1';
import {UserTransaction} from '@/externals/MaxCI-UserTransaction-v1';
import {Order} from '@/externals/MaxCI-Order-v1';
import {getOrderStatus} from '../../api/publicMethod';
import {Coupon, CouponStatus} from '@/externals/MaxCI-Coupon-v1';
import {getUserId} from '../../api/publicMethod';

@Component({
  name: 'userDetail',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private inviteForm = {
    phone: '',
  }; //修改邀请人数据
  private dialogInvite = false; //修改邀请人弹窗
  private integralForm = {
    amount: null as null | number,
    type: '增加',
    reason: '',
  }; //积分数据
  private dialogIntegral = false; //积分修改弹窗
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private tabActive = '账户明细';
  private total = 0;
  private list: Array<UserIntegral | UserTransaction | Order | Coupon> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private userId: ObjectId = ObjectId.createFromHexString(
    this.$route.params.userId,
  );
  private userMsg: null | User = null; //用户信息
  private userViewMsg: null | UserView = null; //用户基本信息
  private inviteInfo = ''; //邀请人信息
  private accountType = '余额';
  private searchData = {
    type: '',
    orderId: '',
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopInfoData = {} as any; //店铺信息
  private orderSummaryData = {
    count: 0,
    account: 0,
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private couponActivitysData = {} as any; //优惠券活动信息

  async created() {
    this.$store.state.applicationId = this.$route.params.applicationId;
    //查询用户信息
    await this.checkUserInfo();
    this.userViewMsg =
      (
        await userViews.find(stage =>
          stage.$match(match => {
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            )(
              f => f('_id'),
              e => e.$eq(this.userId),
            );
            return match;
          }),
        )
      ).find(() => true) ?? null;
    //查询邀请人信息
    if (this.userMsg !== null && this.userMsg.spec.inviterId) {
      const inviterId = this.userMsg.spec.inviterId;
      const inviteInfo = (
        await users.find(stage =>
          stage.$match(match => {
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            )(
              f => f('_id'),
              e => e.$eq(inviterId),
            );
            return match;
          }),
        )
      ).find(() => true);
      this.inviteInfo = inviteInfo ? inviteInfo.spec.phone : '--';
    }

    this.accountDetails(); //账户明细
  }
  @Watch('dialogInvite')
  private changeDialogInvite() {
    if (!this.dialogInvite) {
      this.inviteForm.phone = '';
    }
  }
  @Watch('dialogIntegral')
  private changeDialogIntegral() {
    if (!this.dialogIntegral) {
      this.integralForm.amount = null;
      this.integralForm.type = '增加';
      this.integralForm.reason = '';
    }
  }
  //修改邀请人
  private async inviteSumbit() {
    if (!this.inviteForm.phone) {
      this.$message.error('请输入手机号');
      return false;
    }
    if (!/(^1[23456789]\d{9}$)/.test(this.inviteForm.phone)) {
      this.$message.error('手机号格式不正确');
      return false;
    }
    const user = await users.find(stage =>
      stage.$match(match => {
        match(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        )(
          f => f('spec')('phone'),
          e => e.$eq(this.inviteForm.phone),
        );
        return match;
      }),
    );
    if (user.length === 0) {
      this.$message.error('该用户不存在');
    } else {
      userRequests
        .create(
          [
            {
              spec: {
                type: '修改邀请人',
                inviterId: user[0]._id,
                userId: this.userId,
                applicationId: this.applicationId,
              },
            },
          ],
          {
            watch: {
              filter: filter =>
                filter(
                  f => f('operationType'),
                  e => e.$eq('update'),
                )(
                  f => f('fullDocument')('status')('phase'),
                  e => e.$exists(true),
                ),
            },
            fullResource: true,
          },
        )
        .then(res => {
          if (res[0].status?.phase === '成功') {
            this.$message.success('修改成功');
            this.dialogInvite = false;
            this.inviteInfo = this.inviteForm.phone;
          } else {
            this.$message.error(res[0].status?.conditions[0].message ?? '');
          }
        });
    }
  }
  //查询用户信息
  private async checkUserInfo() {
    this.userMsg =
      (
        await users.find(stage =>
          stage.$match(match => {
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            )(
              f => f('_id'),
              e => e.$eq(this.userId),
            );
            return match;
          }),
        )
      ).find(() => true) ?? null;
  }
  //积分修改保存
  private integralSumbit() {
    if (
      !this.integralForm.amount ||
      !/^[1-9]\d*$/.test(this.integralForm.amount.toString())
    ) {
      this.$message.error('积分调整数值不能为空且必须为大于0的整数');
      return false;
    }
    if (this.userMsg && this.integralForm.type === '减少') {
      const integral = this.userMsg.spec.integral ?? 0;
      if (Number(integral) - Number(this.integralForm.amount) < 0) {
        this.$message.error('扣除积分不能大于当前积分');
        return false;
      }
    }
    if (!this.integralForm.reason) {
      this.$message.error('请填写调整原因');
      return false;
    }
    userIntegralRequests
      .create(
        [
          {
            spec: {
              type: this.integralForm.type as '减少' | '增加',
              operatorId: getUserId(),
              amount: Number(this.integralForm.amount),
              remarks: '客服调整积分',
              reason: this.integralForm.reason,
              userId: this.userId,
              applicationId: this.applicationId,
            },
          },
        ],
        {
          watch: {
            filter: filter =>
              filter(
                f => f('operationType'),
                e => e.$eq('update'),
              )(
                f => f('fullDocument')('status')('phase'),
                e => e.$exists(true),
              ),
          },
          fullResource: true,
        },
      )
      .then(res => {
        if (res[0].status?.phase === '成功') {
          this.$message.success('调整成功');
          this.dialogIntegral = false;
          //this.checkUserInfo();
          const integral = (this.userMsg as User).spec.integral ?? 0;
          (this.userMsg as User).spec.integral =
            this.integralForm.type === '增加'
              ? (integral * 1000 + Number(this.integralForm.amount) * 1000) /
                1000
              : (integral * 1000 - Number(this.integralForm.amount) * 1000) /
                1000;
        } else {
          this.$message.error(res[0].status?.conditions[0].message ?? '');
        }
      });
  }
  //查询优惠券
  private async updateCouponList() {
    const list = await coupons.find(stage =>
      stage
        .$match(match => {
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          )(
            f => f('spec')('userId'),
            e => e.$eq(this.userId),
          );
          return match;
        })
        .$facet(facet =>
          facet('table', tableStage =>
            tableStage
              .$sort(sort =>
                sort(f => f('metadata')('creationTimestamp'), '降序'),
              )
              .$skip((this.listParams.page - 1) * this.listParams.limit)
              .$limit(this.listParams.limit),
          )('count', countStage => countStage.$count('count')),
        ),
    );
    //查询店铺信息
    let couponActivitysData: Array<ObjectId> = [];
    list[0].table.forEach(item => {
      couponActivitysData.push(item.spec.activityId);
    });
    //查询店铺信息
    couponActivitysData = Array.from(new Set(couponActivitysData));
    const couponActivity = await couponActivitys.find(stage =>
      stage.$match(match =>
        match(
          f => f('_id'),
          e => e.$in(couponActivitysData),
        )(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        ),
      ),
    );
    this.couponActivitysData = lodash.zipObject(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      couponActivity.map(v => v._id.toHexString()) as any,
      couponActivity,
    );
    this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
    this.list = list[0].table;
  }
  //获取优惠券类型
  private getCouponActivityType(id: ObjectId) {
    let type = '--';
    if (id && this.couponActivitysData[id.toHexString()]) {
      type = this.couponActivitysData[id.toHexString()].spec.type;
    }
    return type;
  }
  //获取优惠券状态
  private getStatusCoupon(row: Coupon) {
    let status = '';
    if (row.status?.phase === '已使用') {
      status = '已使用';
    } else {
      if (row.spec.term && moment().isBefore(moment(row.spec.term.end))) {
        status = '未使用';
      } else {
        status = '已失效';
      }
    }
    return status;
  }
  //使用时间
  private getUsedTime(status: CouponStatus) {
    let time = '--';
    if (status && status.phase === '已使用') {
      status.conditions.forEach(item => {
        if (item.type === '使用') {
          time = moment(item.creationTimestamp).format('YYYY-MM-DD HH:mm:ss');
        }
      });
    }
    return time;
  }
  //订单管理筛选
  private checkOrder() {
    this.listParams.page = 1;
    this.updateOrder();
  }
  //查询订单
  private async updateOrder() {
    const list = await orders.find(stage =>
      stage
        .$match(match =>
          match.$and(and => {
            and(query => {
              if (this.searchData.orderId) {
                query(
                  f => f('_id'),
                  e =>
                    e.$eq(
                      ObjectId.createFromHexString(this.searchData.orderId),
                    ),
                );
              }
              query(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              )(
                f => f('status')('phase'),
                e => e.$ne('下单'),
              )(
                f => f('spec')('userId'),
                e => e.$eq(this.userId),
              );
              return query;
            });
            and(query =>
              query.$or(or => {
                or(query =>
                  query(
                    f => f('spec')('type'),
                    e => e.$eq('商城'),
                  )(
                    f => f('spec')('serial'),
                    e => e.$exists(true),
                  ),
                );
                or(query =>
                  query(
                    f => f('spec')('type'),
                    e => e.$in(['跑腿', '二手', '会员卡', '充值']),
                  ),
                );
                return or;
              }),
            );
            return and;
          }),
        )
        .$facet(facet =>
          facet('table', tableStage =>
            tableStage
              .$sort(sort =>
                sort(f => f('metadata')('creationTimestamp'), '降序'),
              )
              .$skip((this.listParams.page - 1) * this.listParams.limit)
              .$limit(this.listParams.limit),
          )('count', countStage => countStage.$count('count')),
        ),
    );
    //查询店铺信息
    let shopIdData: Array<ObjectId> = [];
    list[0].table.forEach(item => {
      if (item.spec.type === '商城' && item.spec.shopId) {
        shopIdData.push(item.spec.shopId);
      }
    });
    //查询店铺信息
    shopIdData = Array.from(new Set(shopIdData));
    const shopData = await shops.find(stage =>
      stage.$match(match =>
        match(
          f => f('_id'),
          e => e.$in(shopIdData),
        )(
          f => f('spec')('applicationId'),
          e =>
            e.$eq(
              ObjectId.createFromHexString(this.$route.params.applicationId),
            ),
        ),
      ),
    );
    this.shopInfoData = lodash.zipObject(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      shopData.map(v => v._id.toHexString()) as any,
      shopData,
    );
    this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
    this.list = list[0].table;
  }

  //查询订单汇总
  private async orderSummary() {
    const list = await orders.find(stage =>
      stage
        .$match(match =>
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          )(
            f => f('status')('payInfo'),
            e => e.$exists(true),
          )(
            f => f('spec')('userId'),
            e => e.$eq(this.userId),
          ),
        )
        .$facet(facet =>
          facet('summaryData', summaryStage =>
            summaryStage.$group(
              e => e.$literal(null),
              group =>
                group('account', e =>
                  e.$sum(sum =>
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    sum.$fieldPath(f => f('spec')('amount')('user')),
                  ),
                )('count', e => e.$sum(sum => sum.$literal(1))),
            ),
          ),
        ),
    );
    if (list[0].summaryData.length > 0) {
      this.orderSummaryData.count = list[0].summaryData[0].count;
      this.orderSummaryData.account = list[0].summaryData[0].account;
    }
  }
  //获取商户信息
  private getGoodsName(row: Order) {
    let name = '--';
    if (row.spec.type === '商城') {
      const nameData = row.spec.items?.map(
        v => v.snapshot.name,
      ) as Array<string>;
      name = nameData.join('/');
    } else if (row.spec.type === '跑腿') {
      name = row.spec.agentType;
    } else if (row.spec.type === '二手') {
      name = row.spec.snapshot?.remarks ?? '--';
    } else if (row.spec.type === '充值' || row.spec.type === '会员卡') {
      name = row.spec.snapshot?.name ?? '--';
    }
    return name;
  }
  //获取店铺名称
  private getShopName(row: Order) {
    let name = '--';
    if (row.spec.type === '商城') {
      name = this.shopInfoData[row.spec.shopId.toHexString()]
        ? this.shopInfoData[row.spec.shopId.toHexString()].spec.name
        : '--';
    }
    return name;
  }
  //获取订单状态
  private getStatus(row: Order) {
    if (row.status) {
      return getOrderStatus(row.status, row.spec.type);
    }
  }
  //查看订单
  private checkOrderDetail(id: ObjectId) {
    this.$router.push({
      path: '/application/' + this.$route.params.applicationId + '/order/index',
      query: {
        menu: '订单列表',
        type: 'detail',
        id: id.toHexString(),
      },
    });
  }
  //切换类型账户明细
  private changeAccount() {
    this.listParams.page = 1;
    this.accountDetails();
  }
  //账户明细
  private async accountDetails() {
    if (this.accountType === '余额') {
      const list = await userTransactions.find(stage =>
        stage
          .$match(match => {
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            )(
              f => f('spec')('userId'),
              e => e.$eq(this.userId),
            );
            if (this.searchData.type) {
              match(
                f => f('spec')('direction'),
                e => e.$eq(this.searchData.type as '收入' | '支出'),
              );
            }
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('updationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
    } else {
      const list = await userIntegrals.find(stage =>
        stage
          .$match(match => {
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            )(
              f => f('spec')('userId'),
              e => e.$eq(this.userId),
            );
            if (this.searchData.type) {
              match(
                f => f('spec')('direction'),
                e => e.$eq(this.searchData.type as '收入' | '支出'),
              );
            }
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('updationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
    }
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  //转换时间
  getTime(time: string) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //切换tab
  private changeTab() {
    this.listParams.page = 1;
    if (this.tabActive === '账户明细') {
      this.accountDetails();
    } else if (this.tabActive === '订单管理') {
      this.updateOrder();
      this.orderSummary();
    } else if (this.tabActive === '优惠券') {
      this.updateCouponList();
    }
  }
  //返回
  private backBtn() {
    this.$router.push({
      path: this.$store.state.lastRoute.path,
      query: this.$store.state.lastRoute.query,
    });
  }
}
